import React from 'react';
import emailjs from 'emailjs-com';
import { Link } from "react-scroll";
import HowItWorks1 from './assets/images/how-it-works-1.png';
import HowItWorks2 from './assets/images/how-it-works-2.png';
import HowItWorks3 from './assets/images/how-it-works-3.png';
import Logo from './assets/images/logo.png';
import './App.scss';

interface State {
  message?: string;
}

export default class App extends React.Component {
  state: State = {};

  render(): JSX.Element {
    return (
      <div className="App">
        { this.renderHero() }
        { this.renderForm() }
        { this.renderHowItWorks() }
        { this.renderFooter() }
      </div>
    );
  }

  componentDidMount() {
    emailjs.init("user_jvb6xeIs20JTkDLULe1J7");
  }

  onSubmit(e: any) {
    e.preventDefault();

   var service_id = "default_service";
   var template_id = "template_UKSEo6Y1";

   emailjs.sendForm(service_id, template_id, '#emailform')
    .then(() => this.setState({ message: "Thank you for your interest, we will be in touch shortly."}))
    .catch(() => this.setState({ message: "Sorry, something went wrong. Please email support@learnerscloud.com"}));
  }

  renderHero(): JSX.Element {
    return(
      <div className='hero-wrapper'>
        <div className='hero'>
          <div className='logo-wrapper'>
            <img src={Logo} alt='Learners Cloud' />
          </div>

          <div className='inner'>
            <div className='left'>
              <iframe
                frameBorder={0}
                allowFullScreen
                title='Learners Cloud'
                src="https://www.youtube.com/embed/wrdQEiw8G1A"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
            </div>

            <div className='right'>
              <h1>Free GCSE and iGCSE videos and testing resources for schools</h1>

              <p>In light of the current Coronavirus outbreak (COVID-19), we are reaching out to see if we could be of any assistance to schools.</p>
              <p>We feel that in such difficult times we would like to give something back. We are making our resources available to your school, providing hundreds of videos and questions & answers to assist in your students revision, whilst they are at home. We also have a facility, within our platform, to allow teachers to set assignments for their students.</p>

              <Link
                to="form"
                spy={true}
                smooth={true}
                duration= {500}>Enroll for free access</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderForm(): JSX.Element {
    return <div className='form-wrapper' id='form'>
      <h1>Enroll your school</h1>
      { this.state.message ? <p>{this.state.message}</p>  : <p>Complete the information below and we will send your requested logins to the administrators email address supplied.</p> }

      { this.state.message ? null : <form onSubmit={this.onSubmit.bind(this)} id='emailform'>
        <label htmlFor='school_name'>School Name</label>
        <input name='school_name' type='text' required placeholder='Temple Mead Academy'/>
        
        <label htmlFor='school_addess'>School Address</label>
        <input name='school_addess' type='text' required placeholder='182 Appletree Road, High Holborrn, London, England, WC1X8QP' />
        
        <label htmlFor='administator_name'>Administrator Name</label>
        <input name='administator_name' type='text' required placeholder='John Doe' />
        
        <label htmlFor='administator_email'>Administrator Email</label>
        <input name='administator_email' type='email' required placeholder='johndoe@templemead.com' />
        
        <label htmlFor='num_students'>Number of students</label>
        <input name='num_students' type='number' required min={0} placeholder='0' />
        
        <label htmlFor='num_teachers'>Number of teachers</label>
        <input name='num_teachers' type='number' required min={0} placeholder='0' />

        <input type='submit' value='Submit' />
      </form> }
    </div>
  }

  renderHowItWorks(): JSX.Element {
    return <div className='how-it-works'>
      <h4>How it works</h4>

      <div className='section'>
        <div className='left'>
          <img src={HowItWorks1} alt='A new way to learn' />
        </div>

        <div className='right'>
          <h5>A new way to learn</h5>
          <p>LearnersCloud is an e-learning system that uses blended learning to engage students and strengthen their knowledge of core GCSE and iGCSE subjects.</p>
        </div>
      </div>
    

      <div className='section'>
        <div className='left'>
          <img src={HowItWorks2} alt='Helping you engage learners' />
        </div>

        <div className='right'>
          <h5>Helping you engage learners</h5>
          <p>LearnersCloud has been specially developed to complement classroom teaching.</p>
          <p>You can use it to assess students’ knowledge, identify gaps and set up individual or group tasks like revision assignments and topic tests.</p>
        </div>
      </div>
    

      <div className='section'>
        <div className='left'>
          <img src={HowItWorks3} alt='Core subjects comprehensively covered' />
        </div>

        <div className='right'>
          <h5>Core subjects comprehensively covered</h5>
          <p>LearnersCloud offers modules for GCSE Maths, English, Biology, Chemistry, Physics and iGCSE Maths, Biology and Chemistry.</p>
          <p>Each module includes on-demand tutorial videos, plus Test & Learn questions and is available on a range of digital devices (laptop, tablet, iPhone, Android).</p>
        </div>
      </div>
    </div>
  }

  renderFooter(): JSX.Element {
    return <div className='footer'>
      <p>© LearnersCloud 2020<span>|</span> Free access for new customers only, terms and conditions apply</p>
    </div>
  }
}